import { FC } from 'react';
import IconProps from './IconProps';

const PrivacyByDesignIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35.8572 8.26675C35.8572 7.60676 35.4349 7.02081 34.8088 6.8121L19.706 1.77783L4.60316 6.8121C3.97703 7.02081 3.55471 7.60676 3.55471 8.26675V21.614C3.54977 24.8039 4.49197 27.9235 6.26191 30.5773C8.03185 33.2311 10.5498 35.2998 13.4967 36.521L19.706 39.1112L25.9152 36.521C28.8621 35.2998 31.3801 33.2311 33.15 30.5773C34.92 27.9235 35.8622 24.8039 35.8572 21.614V8.26675ZM32.2681 21.614C32.2724 24.0944 31.5402 26.5203 30.1643 28.5842C28.7884 30.6481 26.8307 32.257 24.5394 33.207L19.706 35.2229L14.8726 33.207C12.5812 32.257 10.6235 30.6481 9.24763 28.5842C7.87172 26.5203 7.13953 24.0944 7.14388 21.614V9.74579L19.706 5.55842L32.2681 9.74579V21.614Z"
          fill="url(#paint0_linear_11030_107475)"
        />
        <path
          d="M15.6639 18.6229C14.9635 17.9225 13.8279 17.9225 13.1275 18.6229C12.4272 19.3233 12.4272 20.4588 13.1275 21.1592L17.2431 25.2748C17.5794 25.6115 18.0355 25.8008 18.5113 25.8012C18.7519 25.7972 18.9892 25.7448 19.2091 25.6471C19.429 25.5495 19.6271 25.4087 19.7914 25.233L27.5374 16.9706C28.2149 16.248 28.1778 15.1128 27.4546 14.4358C26.7312 13.7586 25.5956 13.7968 24.9193 14.5211L18.4694 21.4284L15.6639 18.6229Z"
          fill="#5A5766"
        />
        <defs>
          <linearGradient id="paint0_linear_11030_107475" x1="3.55469" y1="13.1773" x2="37.1624" y2="25.608" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5153 5.01768C21.5153 4.58725 21.2399 4.20511 20.8315 4.06899L11.8246 1.06667L2.81757 4.06899C2.40923 4.20511 2.1338 4.58725 2.1338 5.01768V12.9684C2.13084 14.8823 2.69616 16.754 3.75812 18.3463C4.82008 19.9386 6.33087 21.1798 8.099 21.9126L11.8246 23.4667L15.5501 21.9126C17.3183 21.1798 18.829 19.9386 19.891 18.3463C20.953 16.754 21.5183 14.8823 21.5153 12.9684V5.01768ZM19.3618 12.9684C19.3644 14.4566 18.9251 15.9122 18.0996 17.1505C17.274 18.3888 16.0994 19.3542 14.7246 19.9242L11.8246 21.1337L8.92451 19.9242C7.54972 19.3542 6.3751 18.3888 5.54955 17.1505C4.72401 15.9122 4.2847 14.4566 4.2873 12.9684V5.84744L11.8246 3.33502L19.3618 5.84744V12.9684Z"
        fill="currentColor"
      />
      <path
        d="M9.39795 11.1738C8.97771 10.7535 8.29637 10.7535 7.87614 11.1738C7.4559 11.594 7.4559 12.2754 7.87614 12.6956L10.3455 15.1649C10.5472 15.3669 10.8209 15.4805 11.1064 15.4808C11.2507 15.4783 11.3931 15.4469 11.5251 15.3883C11.657 15.3298 11.7758 15.2452 11.8745 15.1398L16.522 10.1824C16.9285 9.74882 16.9063 9.06771 16.4724 8.66153C16.0383 8.25523 15.357 8.27814 14.9512 8.7127L11.0813 12.8571L9.39795 11.1738Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default PrivacyByDesignIcon;
